const controller = '/sub-domain'
export default axios => ({
  getConfig(obj) {
    return axios.get(`${controller}/config/${obj.subdomainName}`, obj)
  },
  getPageDetails(obj) {
    let parmas = `/`
    if(obj.pageName==='featured'){
        parmas=`?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&orderBy=${obj.orderBy}`
      if(obj.search){
        parmas=parmas+`&search=${obj.search}`
      }
    }
    return axios.get(`${controller}/${obj.subdomainName}/${obj.pageName}${parmas}`, obj)
  },
})
