const actions = {
  async getConfig({ commit }, params) {
    const response = await this.$repositories.subdomain.getConfig(params)
    console.log(response)
    commit('setSubDomainConfig',response.data.data)
    return response.data
  },
  async getPageDetails({ commit }, params) {
    const response = await this.$repositories.subdomain.getPageDetails(params)
    
    return response.data
  },
}
export default actions
