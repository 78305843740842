import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=64f9b557&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=64f9b557&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f9b557",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSharedLang: require('/app/components/ui/shared/lang.vue').default,UiSharedSlider: require('/app/components/ui/shared/slider.vue').default})
