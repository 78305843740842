

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"Thai","code":"th","file":"th.json"},{"name":"English","code":"en","file":"en.json"}],
  defaultLocale: "th",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  localeDetection: false,
  normalizedLocales: [{"name":"Thai","code":"th","file":"th.json"},{"name":"English","code":"en","file":"en.json"}],
  localeCodes: ["th","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "t",
  3: "h",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "t",
  24: "h",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "n",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "e",
  55: "n",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: "}",
}

export const localeMessages = {
  'th.json': () => import('../../locales/th.json' /* webpackChunkName: "lang-th.json" */),
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
}
