
export default {
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    start() {
      // console.log('start loading')
      setTimeout(() => {
        this.isLoading = true
      })
    },
    finish() {
      // console.log('finish loading')
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
  },
}
