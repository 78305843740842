const mutations = {
  setTaxonomy(state, taxonomy) {
    
    state.taxonomy=taxonomy
    // console.log('state.taxonomy', state.taxonomy)
    
    // console.log('localStorage.getItem',localStorage.getItem('taxonomy'))
  },
  getTaxonomyByKey(state, parms) {
    return state.taxonomy[parms.type].find(el => el.key===parms.key)
  },
  getTaxonomyByValue(state, parms) {
    return state.taxonomy[parms.type].find(el => el.value===parms.value)
  }
}
export default mutations
