export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../components/contact/index.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterContactUs = () => import('../../components/helpCenter/ContactUs.vue' /* webpackChunkName: "components/help-center-contact-us" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterDoNotSell = () => import('../../components/helpCenter/DoNotSell.vue' /* webpackChunkName: "components/help-center-do-not-sell" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterFaq = () => import('../../components/helpCenter/Faq.vue' /* webpackChunkName: "components/help-center-faq" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterPolicy = () => import('../../components/helpCenter/Policy.vue' /* webpackChunkName: "components/help-center-policy" */).then(c => wrapFunctional(c.default || c))
export const HelpCenterTermsAndCondition = () => import('../../components/helpCenter/TermsAndCondition.vue' /* webpackChunkName: "components/help-center-terms-and-condition" */).then(c => wrapFunctional(c.default || c))
export const PropertyCard = () => import('../../components/property/Card.vue' /* webpackChunkName: "components/property-card" */).then(c => wrapFunctional(c.default || c))
export const PropertySlider = () => import('../../components/property/Slider.vue' /* webpackChunkName: "components/property-slider" */).then(c => wrapFunctional(c.default || c))
export const PropertyManagementHeader = () => import('../../components/propertyManagement/Header.vue' /* webpackChunkName: "components/property-management-header" */).then(c => wrapFunctional(c.default || c))
export const UiImageUpload = () => import('../../components/ui/ImageUpload.vue' /* webpackChunkName: "components/ui-image-upload" */).then(c => wrapFunctional(c.default || c))
export const UiBaseMenu = () => import('../../components/ui/base/Menu.vue' /* webpackChunkName: "components/ui-base-menu" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconCoin = () => import('../../components/ui/icons/IconCoin.vue' /* webpackChunkName: "components/ui-icons-icon-coin" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconDollar = () => import('../../components/ui/icons/IconDollar.vue' /* webpackChunkName: "components/ui-icons-icon-dollar" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconDoubleArrowUp = () => import('../../components/ui/icons/IconDoubleArrowUp.vue' /* webpackChunkName: "components/ui-icons-icon-double-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconEmailRepeat = () => import('../../components/ui/icons/IconEmailRepeat.vue' /* webpackChunkName: "components/ui-icons-icon-email-repeat" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconLand = () => import('../../components/ui/icons/IconLand.vue' /* webpackChunkName: "components/ui-icons-icon-land" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconLine = () => import('../../components/ui/icons/IconLine.vue' /* webpackChunkName: "components/ui-icons-icon-line" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconListPlus = () => import('../../components/ui/icons/IconListPlus.vue' /* webpackChunkName: "components/ui-icons-icon-list-plus" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconMailBulk = () => import('../../components/ui/icons/IconMailBulk.vue' /* webpackChunkName: "components/ui-icons-icon-mail-bulk" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconMotorcycle = () => import('../../components/ui/icons/IconMotorcycle.vue' /* webpackChunkName: "components/ui-icons-icon-motorcycle" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconPaypal = () => import('../../components/ui/icons/IconPaypal.vue' /* webpackChunkName: "components/ui-icons-icon-paypal" */).then(c => wrapFunctional(c.default || c))
export const UiIconsIconSun = () => import('../../components/ui/icons/IconSun.vue' /* webpackChunkName: "components/ui-icons-icon-sun" */).then(c => wrapFunctional(c.default || c))
export const UiSharedCarousel = () => import('../../components/ui/shared/Carousel.vue' /* webpackChunkName: "components/ui-shared-carousel" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDataTable = () => import('../../components/ui/shared/DataTable.vue' /* webpackChunkName: "components/ui-shared-data-table" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDatePicker = () => import('../../components/ui/shared/DatePicker.vue' /* webpackChunkName: "components/ui-shared-date-picker" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFooter = () => import('../../components/ui/shared/Footer.vue' /* webpackChunkName: "components/ui-shared-footer" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFooterManager = () => import('../../components/ui/shared/FooterManager.vue' /* webpackChunkName: "components/ui-shared-footer-manager" */).then(c => wrapFunctional(c.default || c))
export const UiSharedGreetings = () => import('../../components/ui/shared/Greetings.vue' /* webpackChunkName: "components/ui-shared-greetings" */).then(c => wrapFunctional(c.default || c))
export const UiSharedHomeHeader = () => import('../../components/ui/shared/HomeHeader.vue' /* webpackChunkName: "components/ui-shared-home-header" */).then(c => wrapFunctional(c.default || c))
export const UiSharedIconText = () => import('../../components/ui/shared/IconText.vue' /* webpackChunkName: "components/ui-shared-icon-text" */).then(c => wrapFunctional(c.default || c))
export const UiSharedLoadingOverlay = () => import('../../components/ui/shared/LoadingOverlay.vue' /* webpackChunkName: "components/ui-shared-loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMobileHeader = () => import('../../components/ui/shared/MobileHeader.vue' /* webpackChunkName: "components/ui-shared-mobile-header" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMobileMenu = () => import('../../components/ui/shared/MobileMenu.vue' /* webpackChunkName: "components/ui-shared-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const UiSharedPaginations = () => import('../../components/ui/shared/Paginations.vue' /* webpackChunkName: "components/ui-shared-paginations" */).then(c => wrapFunctional(c.default || c))
export const UiSharedLang = () => import('../../components/ui/shared/lang.vue' /* webpackChunkName: "components/ui-shared-lang" */).then(c => wrapFunctional(c.default || c))
export const UiSharedSlider = () => import('../../components/ui/shared/slider.vue' /* webpackChunkName: "components/ui-shared-slider" */).then(c => wrapFunctional(c.default || c))
export const UiSharedSmiley = () => import('../../components/ui/shared/smiley.vue' /* webpackChunkName: "components/ui-shared-smiley" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsConfirm = () => import('../../components/ui/shared/dialogs/Confirm.vue' /* webpackChunkName: "components/ui-shared-dialogs-confirm" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsDialogMBlur = () => import('../../components/ui/shared/dialogs/DialogMBlur.vue' /* webpackChunkName: "components/ui-shared-dialogs-dialog-m-blur" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsDialogMFullPage = () => import('../../components/ui/shared/dialogs/DialogMFullPage.vue' /* webpackChunkName: "components/ui-shared-dialogs-dialog-m-full-page" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsLogin = () => import('../../components/ui/shared/dialogs/Login.vue' /* webpackChunkName: "components/ui-shared-dialogs-login" */).then(c => wrapFunctional(c.default || c))
export const UiSharedDialogsScheduleDialog = () => import('../../components/ui/shared/dialogs/ScheduleDialog.vue' /* webpackChunkName: "components/ui-shared-dialogs-schedule-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiSharedErrorsErrorDialog = () => import('../../components/ui/shared/errors/ErrorDialog.vue' /* webpackChunkName: "components/ui-shared-errors-error-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiSharedErrorsErrorMessage = () => import('../../components/ui/shared/errors/ErrorMessage.vue' /* webpackChunkName: "components/ui-shared-errors-error-message" */).then(c => wrapFunctional(c.default || c))
export const UiSharedErrorsErrorToast = () => import('../../components/ui/shared/errors/ErrorToast.vue' /* webpackChunkName: "components/ui-shared-errors-error-toast" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFileUploaderAdd = () => import('../../components/ui/shared/fileUploader/Add.vue' /* webpackChunkName: "components/ui-shared-file-uploader-add" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFileUploaderImgEditor = () => import('../../components/ui/shared/fileUploader/ImgEditor.vue' /* webpackChunkName: "components/ui-shared-file-uploader-img-editor" */).then(c => wrapFunctional(c.default || c))
export const UiSharedFileUploader = () => import('../../components/ui/shared/fileUploader/index.vue' /* webpackChunkName: "components/ui-shared-file-uploader" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMapArea = () => import('../../components/ui/shared/map/Area.vue' /* webpackChunkName: "components/ui-shared-map-area" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMapCustomMarker = () => import('../../components/ui/shared/map/CustomMarker.vue' /* webpackChunkName: "components/ui-shared-map-custom-marker" */).then(c => wrapFunctional(c.default || c))
export const UiSharedMapGoogle = () => import('../../components/ui/shared/map/Google.vue' /* webpackChunkName: "components/ui-shared-map-google" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
