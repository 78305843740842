
import { mapMutations } from 'vuex'

export default {
  model: {
    prop: 'dialog',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    hideToolBar: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [String, Number],
      default: '460px',
    },
  },
  watch: {
    dialog(val) {
      console.log('dialog', val)
      this.setBlur(val)
    },
  },
  destroyed() {
    console.log('destroyed')
  },
  methods: {
    ...mapMutations('config/site', ['setBlur']),

    closeDialog() {
      console.log('close dialog')
      this.$emit('input', false)
      this.$emit('dialog', false)
    },
  },
}
