import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aea7636a = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _81c6872c = () => interopDefault(import('../pages/for-rent/index.vue' /* webpackChunkName: "pages/for-rent/index" */))
const _1e24a7f8 = () => interopDefault(import('../pages/for-sale/index.vue' /* webpackChunkName: "pages/for-sale/index" */))
const _de783be8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _7b1e822e = () => interopDefault(import('../pages/new-listings/index.vue' /* webpackChunkName: "pages/new-listings/index" */))
const _cfd2c176 = () => interopDefault(import('../pages/recommend/index.vue' /* webpackChunkName: "pages/recommend/index" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _217ce4b2 = () => interopDefault(import('../pages/video-library/index.vue' /* webpackChunkName: "pages/video-library/index" */))
const _f561e87c = () => interopDefault(import('../pages/help-center/_name.vue' /* webpackChunkName: "pages/help-center/_name" */))
const _f4ab0210 = () => interopDefault(import('../pages/listing/_id/index.vue' /* webpackChunkName: "pages/listing/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _aea7636a,
    name: "about-us___th"
  }, {
    path: "/contact",
    component: _1d238929,
    name: "contact___th"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/for-rent",
    component: _81c6872c,
    name: "for-rent___th"
  }, {
    path: "/for-sale",
    component: _1e24a7f8,
    name: "for-sale___th"
  }, {
    path: "/home",
    component: _de783be8,
    name: "home___th"
  }, {
    path: "/new-listings",
    component: _7b1e822e,
    name: "new-listings___th"
  }, {
    path: "/recommend",
    component: _cfd2c176,
    name: "recommend___th"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search___th"
  }, {
    path: "/video-library",
    component: _217ce4b2,
    name: "video-library___th"
  }, {
    path: "/en/about-us",
    component: _aea7636a,
    name: "about-us___en"
  }, {
    path: "/en/contact",
    component: _1d238929,
    name: "contact___en"
  }, {
    path: "/en/for-rent",
    component: _81c6872c,
    name: "for-rent___en"
  }, {
    path: "/en/for-sale",
    component: _1e24a7f8,
    name: "for-sale___en"
  }, {
    path: "/en/home",
    component: _de783be8,
    name: "home___en"
  }, {
    path: "/en/new-listings",
    component: _7b1e822e,
    name: "new-listings___en"
  }, {
    path: "/en/recommend",
    component: _cfd2c176,
    name: "recommend___en"
  }, {
    path: "/en/search",
    component: _6cfe81d6,
    name: "search___en"
  }, {
    path: "/en/video-library",
    component: _217ce4b2,
    name: "video-library___en"
  }, {
    path: "/en/help-center/:name?",
    component: _f561e87c,
    name: "help-center-name___en"
  }, {
    path: "/en/listing/:id",
    component: _f4ab0210,
    name: "listing-id___en"
  }, {
    path: "/help-center/:name?",
    component: _f561e87c,
    name: "help-center-name___th"
  }, {
    path: "/listing/:id",
    component: _f4ab0210,
    name: "listing-id___th"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___th"
  }],

  fallback: true
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
