import Vue from 'vue'

export default function ({ $moment, app: { i18n } }) {
  Vue.filter('priceDecim', val => {
    return val
      ? parseFloat(val).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : ''
  })
  Vue.filter('priceRound', val => {
    return val
      ? Math.round(parseFloat(val)).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : ''
  })
  // DATES
  Vue.filter('datell', val => {
    return val ? new $moment(val).locale(i18n.locale).format('ll') : '-'
  })
  Vue.filter('dateLL', val => {
    return val ? new $moment(val).locale(i18n.locale).format('LL') : '-'
  })
  Vue.filter('dateLLLL', val => {
    return val ? new $moment(val).locale(i18n.locale).format('LLLL') : '-'
  })
  // time
  Vue.filter('dateLT', val => {
    return val ? new $moment(val).locale(i18n.locale).format('LT') : '-'
  })
  Vue.filter('dateLTS', val => {
    return val ? new $moment(val).locale(i18n.locale).format('LTS') : '-'
  })
}
