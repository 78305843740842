import Vue from 'vue'

Vue.prototype.$breakpoint = new Vue({
  data() {
    return {
      mountedBreakpoints: {},
      isMounted: false,
      default: {
        name: 'xs',
        mobile: true,
        xs: true,
        xsOnly: true,
        sm: false,
        smOnly: false,
        smAndDown: true,
        smAndUp: false,
        md: false,
        mdOnly: false,
        mdAndDown: true,
        mdAndUp: false,
        lg: false,
        lgOnly: false,
        lgAndDown: true,
        lgAndUp: false,
        xl: false,
        xlOnly: false,
      },
    }
  },
  computed: {
    is() {
      return Object.keys(this.$vuetify.default).reduce((breakpoints, dim) => {
        breakpoints[dim] = this.breakpointWithDefault(dim)
        return breakpoints
      }, {})
    },
  },
  methods: {
    breakpointWithDefault(breakpoint) {
      return Object.keys(this.$data.mountedBreakpoints).length > 0
        ? this.$data.mountedBreakpoints[breakpoint]
        : this.$data.default[breakpoint]
    },
  },
})

export default function ({ app }) {
  if (!app.mixins) {
    app.mixins = []
  }
  if (!Vue.__BREAKPOINT__) {
    Vue.__BREAKPOINT__ = true
    app.mixins.push({
      mounted() {
        setTimeout(() => {
          this.$breakpoint.$data.mountedBreakpoints = this.$vuetify.breakpoint
          this.$breakpoint.$data.isMounted = true
          // to change timeout => store/config/site/state : loadingTimeout
        }, 1000)
        // app.store.state.config.site.loadingTimeout
      },
    })
  }
}
