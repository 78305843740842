
const actions = {
  setErrorSnackbar({ commit }, snackbar) {
    snackbar.showing = true
    snackbar.color = snackbar.color || 'error'
    snackbar.timeout = snackbar.timeout + Math.random() || 4000 + Math.random() // change timeout to reset it

    commit('setErrorSnackbar', snackbar)
  },
  setDiscountCodeNotifcationHide({ commit }, discountCodeNotifcation) {
    commit('setDiscountCodeNotifcation', discountCodeNotifcation)
    return true
  },
  scrollTop({ commit },) {
    window.$nuxt.$vuetify.goTo(0, {
      duration: 500,
      offset: 0,
      easing: 'easeInOutCubic',
    })
    return true
  },
  async getSiteConfig({ commit }) {
    const res = await this.$repositories.siteConfig.getSiteConfig()
    console.log("getSiteConfig",res)
    if (res.data.data) {
      commit('setSiteConfig', res.data.data)
    }
    return res.data.data
  },
  async setTypeOfLoginwant({ commit }, typeOfLogin) {

    if (typeOfLogin) {
      commit('setTypeOflogin', typeOfLogin)
    }
    return typeOfLogin
  },
}
export default actions
