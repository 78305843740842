import Vue from 'vue'
import {
    TwemojiPicker
  } from '@kevinfaguiar/vue-twemoji-picker';



// import css
// import 'vue3-twemoji-picker-final/dist/index.css'

Vue.component('twemoji-picker', TwemojiPicker)
