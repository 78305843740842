
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('config/site', ['errorSnackbar']),
  },
  methods: {
    ...mapMutations('config/site', ['closeErrorSnackbar']),
  },
}
