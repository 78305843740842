
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'DefaultLayout',
  data() {
    return {
      loginDialog: false,
      showSchedule: false,
      showScrollUpButton: false,
      clipped: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/',
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Inspire',
          to: '/inspire',
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js',
      firstLoad: true,
    }
  },
  head() {
    return {
      link: [
        {
          hid: 'favicon',
          rel: 'icon',
          type: 'image/png',
          href: this.siteConfig.favIcon,
        },
      ],
      style: [
        {
          hid: 'vuetifyTheme',
          type: 'text/css',
          cssText: this.$vuetify.theme.generatedStyles,
        },
      ],
    }
  },
  watch: {
    isScheduleShowOpen() {
      console.log('isScheduleShowOpen==default>>>', this.isScheduleShowOpen)
      this.showSchedule =
        this.isScheduleShowOpen.demo || this.isScheduleShowOpen.visit
    },
    // isLogInDialogShowOpen() {
    //   this.loginDialog = this.isLogInDialogShowOpen
    // },
    // '$auth.loggedIn'() {
    //   if (!this.firstLoad) {
    //     // this.connectSocket()
    //   }
    // },
  },
  computed: {
    ...mapState('config/site', [
      'isBlur',
      'siteConfig',
      'isScheduleShowOpen',
      'isLogInDialogShowOpen',
    ]),
  },
  // created() {
  //   this.resetHeaderState()
  // },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    // this.connectSocket()
    this.firstLoad = false
    if (this.$auth.loggedIn) {
      const token = this.$auth.$storage
        .getState('_token.local')
        .match(/\s(.*)/)[1]

      console.log(token)
      // if (token) {
      //   this.$root.socket = this.$nuxtSocket({
      //     name: 'home',
      //     query: { token },
      //     reconnection: false,
      //     emitTimeout: 1000
      //   })

      //   console.log('socket', this.$root.socket)

      //   this.$root.socket.on('MyEvent', msg => {
      //     console.log(msg)
      //   })
      //   this.$root.socket.emit("JoinChatRoom", 1, msg => {
      //     console.log(msg)
      //   })
      //   this.$root.socket.on('connect', function (msg) {
      //     console.log(msg)
      //   });
      //   this.$root.socket.on('connecting', function (msg) {
      //     console.log(msg)
      //   });
      //   this.$root.socket.on('disconnect', function (msg) {
      //     console.log(msg)
      //   });
      //   // this.$root.socket.on('chat-message-admin', msg => {
      //   //   this.setUnreadMessage(this.unreadMessages + 1)
      //   // })
      // }
    }
  },
  methods: {
    // ...mapMutations('config/site', ['setLogInDialogShow']),
    // ...mapActions('config/menu', ['resetHeaderState']),
    handleScroll() {
      const topBtn = document.querySelector('#scroll-button')
      if (topBtn) {
        topBtn.style.display = 'none'
        if (
          document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20
        ) {
          topBtn.style.display = 'block'
        } else {
          topBtn.style.display = 'none'
        }
      }
    },
    scrollUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    // async connectSocket() {
    //   if (this.$auth.loggedIn) {
    //     const token = this.$auth.$storage
    //       .getState('_token.local')
    //       .match(/\s(.*)/)[1]

    //     console.log(token)
    //     if (token) {
    //       this.$root.socket = await this.$nuxtSocket({
    //         forceNew: false,
    //         query: { token },
    //         reconnection: false,
    //       })

    //       console.log('socket', this.$root.socket)

    //       this.$root.socket.on('ReceiveMessage', msg => {
    //         console.log(msg)
    //       })
    //       this.$root.socket.emit('JoinChatRoom', 1, msg => {
    //         console.log(msg)
    //       })
    //       this.$root.socket.on('connect', function (msg) {
    //         console.log(msg)
    //       })
    //       this.$root.socket.on('connecting', function (msg) {
    //         console.log(msg)
    //       })
    //       this.$root.socket.on('disconnect', function (msg) {
    //         console.log(msg)
    //       })
    //       // this.$root.socket.on('chat-message-admin', msg => {
    //       //   this.setUnreadMessage(this.unreadMessages + 1)
    //       // })
    //     }
    //   }
    // },
  },
  destroyed() {
    // this.setLogInDialogShow(false)
  },
}
