import faq from '~/api/common/faq'
import siteConfig from '~/api/common/siteConfig'
import getTaxonomy from '~/api/common/taxonomy'
import property from '~/api/property/property'
import subdomain from '~/api/subdomain/index'
import stats from '~/api/home/stats'
import demo from '~/api/common/demo'

export default axios => ({
  siteConfig: siteConfig(axios),
  faq: faq(axios),
  getTaxonomy: getTaxonomy(axios),
  property: property(axios),
  subdomain: subdomain(axios),
  stats: stats(axios),
   demo: demo(axios),
})
