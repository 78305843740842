const controller = '/taxonomy'
export default axios => ({
  getTaxonomy(obj) {
    return axios.get(`${controller}`, obj)
  },
  getPublicVideos(obj) {
    let parmas = `?categoryId=${obj.categoryId}&itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    return axios.get(`${controller}/get-video-library${parmas}`, obj)
  },
})
