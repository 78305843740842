
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  props: {
    showBanner: {
      type: Boolean,
      default: true,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    showOwnersButton: {
      type: Boolean,
      default: true,
    },
    showHeaderNav: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loginDialog: false,
      subdomainName: null,
      managerConfig: null,
      bannerText: '',
      disableOwner: false,
      disableTenant: false,
    }
  },
  watch: {
    isLogInDialogShowOpen() {
      this.loginDialog = this.isLogInDialogShowOpen
    },
    managerConfig() {
      this.bannerText =
        this.managerConfig[`bannerText${this.$vuetify.lang.current}`]
    },
    '$auth.loggedIn'() {
      this.checkUser()
    },
  },
  computed: {
    ...mapState('taxonomy', ['taxonomy']),
    ...mapState('config/site', [
      'isBlur',
      'siteConfig',
      'isScheduleShowOpen',
      'isLogInDialogShowOpen',
    ]),
  },
  created() {
    this.resetHeaderState()
    this.checkUser()
  },
  mounted() {
    const host = window.location.host
    const parts = host.split('.')
    console.log(parts)
    this.subdomainName = parts[0]
    if (this.subdomainName) {
      this.getSubDomainConfig()
    }
  },
  // destroyed(){
  //   console.log('destroyed')
  //   this.$vuetify.theme.themes.light.primary = this.siteConfig.primaryColor
  // },
  methods: {
    ...mapMutations('config/site', ['setLogInDialogShow']),
    ...mapActions('config/site', ['setTypeOfLoginwant']),
    ...mapActions('config/menu', ['resetHeaderState']),
    ...mapActions('subdomain', ['getConfig']),
    checkUser() {
      console.log(this.$auth.user)
      if (this.$auth.loggedIn) {
        if (this.$auth.user.role === 'Tenant') {
          this.disableOwner = true
        }
        if (this.$auth.user.role === 'Owner') {
          this.disableTenant = true
        }
      }
    },
    async getSubDomainConfig() {
      //   if (process.client) {
      //   this.$nuxt.$loading.start()
      // }
      const res = await this.getConfig({ subdomainName: this.subdomainName })
      this.managerConfig = res.data
      if (this.managerConfig) {
        this.$vuetify.theme.themes.light.primary =
          this.managerConfig.primaryColor
        console.log(this.$vuetify)
        if (process.client) {
          this.$nuxt.$loading.finish()
        }
      }
    },
    checkLogin(typeOfLogin) {
      console.log('checkLogin', typeOfLogin)
      if (this.$auth.loggedIn) {
        this.$router.push(
          `/dashboard/${this.$auth.user.role.toLowerCase()}/main`
        )
      } else {
        this.setTypeOfLoginwant({ typeOfLogin }).then(data => {
          console.log(data)
          this.setLogInDialogShow(true)
        })
      }
    },
  },
}
