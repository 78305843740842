const state = () => ({
  siteConfig: {},
  errorSnackbar: { showing: false },
  headerHeight: null,
  isBlur: false,
  isScheduleShowOpen: {
    demo: false,
    visit: false,
  },
  isLogInDialogShowOpen: false,
  redirectUrl: null,
  loadingTimeout: 1300,
  typeOfLogin: 2,
})
export default state
