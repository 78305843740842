
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      userInfo: this.$auth.user,
      flipped: false,
    }
  },
  created() {
    // console.log('this.$auth', this.$auth)
    console.log('this.siteConfig', window.location.origin)
    this.$vuetify.theme.themes.light.primary = this.siteConfig.primaryColor
  },
  computed: {
    ...mapState('config/site', ['siteConfig']),
    disabledMenus() {
      if (process.browser) {
        return (
          window.location.origin === 'https://properlly.com' ||
          window.location.origin === 'https://www.properlly.com' ||
          window.location.origin === 'https://properlly.co.th' ||
          window.location.origin === 'https://www.properlly.co.th'
        )
      } else {
        return false
      }
    },
  },
  watch: {
    origin(val) {
      console.log('this.origin', this.origin)
    },
    // '$auth.user'() {
    //   this.userInfo = this.$auth.user
    //   console.log('headewr', this.$auth.user)
    // },
  },
  methods: {
    ...mapMutations('config/site', ['setLogInDialogShow', 'setScheduleShow']),
    // ...mapActions('config/site', ['setTypeOfLoginwant']),
    scheduleShow() {
      this.setScheduleShow({ demo: true, visit: false })
    },
    // logInDialogShow(type) {
    //   this.setLogInDialogShow(true)
    // },
    async scrollToFeatured() {
      const navigationResult = await this.$router.push('./home')

      if (navigationResult) {
        // navigation prevented
      } else {
        setTimeout(() => {
          // navigation succeeded (this includes the case of a redirection)
          window.$nuxt.$vuetify.goTo('#featuredSection', {
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic',
          })
        }, 1000)
      }
    },
    showLogin() {
      this.setTypeOfLoginwant({ typeOfLogin: 2 }).then(data => {
        console.log(data)
        this.setLogInDialogShow(true)
      })
    },
  },
}
